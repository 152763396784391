import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import socketIOClient from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import { Title } from "utils/Title";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import Banner from "@leafygreen-ui/banner";
import { axiosInstance as axios } from "configs/axiosConfig";
import { getPackingItemsByOrder, getPackingItems } from "utils/packingItems";
import "react-toastify/dist/ReactToastify.css";
import { getS3presign } from "utils/awsS3";
import { ModalPreloader } from "utils/Preloader";
import queryString from "query-string";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getShipStationOrder } from "utils/shipstation";
import { getItemDoc } from "utils/items";
import _ from "lodash";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import Checkbox from "@leafygreen-ui/checkbox";
import Button from "@leafygreen-ui/button";
import {
  FaBold,
  FaCheckCircle,
  FaPrint,
  FaQrcode,
  FaTrashAlt,
  FaWindowClose,
} from "react-icons/fa";
import moment from "moment";
import { GridLoader } from "react-spinners";
import { errorHandler } from "utils/errorHandler";
import ItemOptions from "utils/ItemOptions";
import { differenceInDays, format, toDate } from "date-fns";
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import { skuLabelTemplateQR } from "utils/skuLabelTemplateQR";
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { printOrderItemLabel } from "utils/printOrderItemLabel";

const title = "Queue Print";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const createXml = ({ item, selectedPosition, dimensions }) => {
  console.log("* createXml init");
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/rip/queue`;
      const response = await axios.post(url, {
        item,
        selectedPosition,
        dimensions,
      });
      resolve(response.data);
    } catch (error) {
      let retval = errorHandler(error);
      reject(retval);
    }
  });
};

const getGTXQueueInfo = ({
  item,
  selectedPosition,
  gtxPreset,
  dimensions,
  printQty,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/gtx/queue`;
      const response = await axios.post(url, {
        item,
        selectedPosition,
        gtxPreset,
        dimensions,
        printQty,
      });
      resolve(response.data);
    } catch (error) {
      let retval = errorHandler(error);
      reject(retval);
    }
  });
};

const fetchPackingItem = ({ queryKey }) => {
  console.log("* fetchPackingItem init");
  return new Promise(async (resolve, reject) => {
    try {
      console.log("- queryKey: ", queryKey);
      let condition = queryKey[1];
      if (condition) {
        const response = await getPackingItemsByOrder({ ...condition });
        if (!Boolean(response.length)) {
          throw new Error("No packing item found");
        } else {
          resolve(response[0]);
        }
      } else {
        resolve(null);
      }
    } catch (error) {
      let retval = errorHandler(error);
      reject(retval);
    }
  });
};

const fetchActivePackingItems = (sku) => {
  console.log("* fetchActivePackingItems init");
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("- sku: ", sku);
      let condition = { sku, isActive: true };
      if (condition) {
        const response = await getPackingItems({ sku });
        // console.log("- getPackingItems response: ", response);
        if (!Boolean(response.length)) {
          throw new Error("No packing item found");
        } else {
          resolve(response);
        }
      } else {
        resolve(null);
      }
    } catch (error) {
      let retval = errorHandler(error);
      reject(retval);
    }
  });
};


const integratedAutomations = [
  { value: "KOTHARI", name: "Kothari Automation" },
  { value: "GTXCMD", name: "Brother GTX CMD Line" },
  { value: "CADLINK", name: "CADlink Digital Factory" },
];

const color = "#adadad";
const highlightColor = "#252525";

export default function QueuePrint({ location, history }) {
  const { settings, platens, hostname, sessions } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableQueueBtn, setDisableQueueBtn] = useState(true);
  const [disableCancelQueueBtn, setDisableCancelQueueBtn] = useState(true);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [preSignedUrl, setPreSignedUrl] = useState(null);
  const [graphicDimensions, setGraphicDimension] = useState(null);
  const [condition, setCondition] = useState(null);
  const [, setPlatenValue] = useState(null);
  const [customPlaten, setCustomPlaten] = useState(null);
  const [loadedArtwork, setLoadedArtwork] = useState(false);
  const [printQty, setPrintQty] = useState(1);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const itemRef = useRef(null);
  const ssOrderRef = useRef(null);
  const selectedPositionRef = useRef(null);
  const printQtyRef = useRef(1);
  const gtxPresetRef = useRef(null);
  const isQueueCompletedRef = useRef(true);
  const settingsRef = useRef(null);
  const bannerVariant = useRef("info");
  let queueCountRef = useRef(null);

  const conditionRef = useRef(null);
  const graphicsRef = useRef(null);
  const [sessionMsg, setSessionMsg] = useState(null);
  const isSessionValid = useRef(true);
  // const [activePackingItems, setActivePackingItems] = useState(null);
  const activePackingItemsRef = useRef(null);
  const [selectedCadlinkQueue, setSelectedCadlinkQueue] = useState(null)
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)
  const selectedAutomationRef = useRef(null)
  const isManualOrderRef = useRef(false);

  const {
    data: item,
    refetch: refetchPackingItem,
    isLoading,
  } = useQuery(["item", condition], fetchPackingItem, {
    enabled: false,
    refetchOnWindowFocus: false,
    onError: (error) => {
      setMsg(error);
      setDisableQueueBtn(true);
    },
  });


  const { data: ssOrder, isLoading: isLoadingSS, refetch: refetchSSOrder } = useQuery(
    ["ssOrder"],
    async () => {
      console.log("* refetchSSOrder init");
      // console.log('- conditionRef: ', conditionRef.current)
      let orderId = conditionRef.current?.orderId;
      // console.log("- orderId: ", orderId);
      if (orderId) {
        return await getShipStationOrder(parseInt(orderId));
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        ssOrderRef.current = data
      }
    }
  );

  const { data: itemDoc, refetch: refetchItemDoc } = useQuery(
    ["itemDoc", condition],
    async () => {
      console.log("* refetchItemDoc init");
      // console.log('- conditionRef: ', conditionRef.current)
      let sku = condition?.sku
        ? condition.sku
        : conditionRef.current?.sku ?? conditionRef.current.sku;
      console.log("- sku: ", sku);
      if (sku) {
        return await getItemDoc(sku);
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // console.log(data)
        if(_.isNil(data)) {
          bannerVariant.current = "danger"
          setMsg("Unable to retrieve item info.")
        }
      }
    }
  );

  const { refetch: refetchActivePackingItems } = useQuery(
    ["activePackingItems", condition],
    async () => {
      console.log("* refetchActivePackingItems init");
      // console.log('- conditionRef: ', conditionRef.current)
      let sku = condition?.sku
        ? condition.sku
        : conditionRef.current?.sku ?? conditionRef.current.sku;
      // console.log("- sku: ", sku);
      if (sku) {
        return await fetchActivePackingItems(sku);
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // console.log("- activePackingItems data; ", data);
        activePackingItemsRef.current = data;
      },
    }
  );

  const queryClient = useQueryClient();

  // console.log("- item", item);
  // console.log("- ssOrder", ssOrder);
  // if (ssOrder) ssOrderRef.current = ssOrder;
  // console.log("- itemDoc", itemDoc);
  // console.log("- isLoading", isLoading);
  // console.log("- settings.autoQueue: ", settings);

    //settings hook
  useEffect(() => {
    console.log("* settings hook init");
    // console.log('- settings: ', settings)
    if (settings) {
      settingsRef.current = settings;
      setSelectedAutomation(settings.defaultIntegratedAutomation || "KOTHARI");
      selectedAutomationRef.current = settings.defaultIntegratedAutomation || "KOTHARI";
    }
  }, [settings]);

  //onQueueSubscribeCallback
  const onQueueSubscribeCallback = useCallback(() => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log(`* onQueueSubscribeCallback `);
      console.log('- isSessionValid:', isSessionValid)
      if(!isSessionValid.current) {
        if(sessionMsg) alert(sessionMsg);
        return
      }
      console.log(
        "- settings.current?.autoQueue: ", settingsRef.current?.autoQueue
      );
      console.log("- isQueueCompleted: ", isQueueCompletedRef.current);
      // console.log("- itemRef: ", itemRef.current);
      let queryParams = queryString.parse(location.search);
      console.log("- queryParams: ", queryParams);
      if (queryParams?.sku) {
        history.push({ pathname: location.pathname, search: "" });
        alert("Encounter with old parameters, reload this page and scan again");
        window.location.reload();
        return;
      }

      // if(settingsRef.current?.autoQueue && !isQueueCompletedRef.current && itemRef.current) {
      if (!isQueueCompletedRef.current && itemRef.current) {
        let txt =
          "Data is being loaded or loading is completed, but queue print is not performed. ";
        if (settingsRef.current?.autoQueue) {
          txt = txt + "Scan a sticker after data loading completed or queued";
          window.alert(txt);
          return;
        } else {
          txt = txt + "Confirm OK to cancel the queue and load new data!";
          const confirm = window.confirm(txt);
          console.log("- confirm: ", confirm);
          if (!confirm) return;
        }
      }

      toast.dismiss();
      queryClient.removeQueries('item')
      queryClient.removeQueries('ssOrder')
      queryClient.removeQueries('itemDoc')
      queryClient.removeQueries('activePackingItems')
      ssOrderRef.current = null;
      itemRef.current = null;
      graphicsRef.current = null;
      setDisableQueueBtn(true);
      // setDisableQueueBtn(false);
      
      setSelectedPosition(null);
      selectedPositionRef.current = null;
      
      queueCountRef.current = null;
      disableCancelQueueBtn && setDisableCancelQueueBtn(true);
      activePackingItemsRef.current = null;
      bannerVariant.current = "info"

      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);

      console.log('- dataArr: ', dataArr)
      let sku = dataArr[0];
      let orderId = dataArr[1];
      let orderItemId = dataArr[2];
      let graphicPosition = dataArr[3];
      console.log("- sku", sku);
      console.log("- orderId", orderId, typeof orderId);
      console.log("- orderItemId", orderItemId, typeof orderItemId);
      console.log("- graphicPosition", graphicPosition);
      let condition = { sku };
      if (orderId) condition.orderId = orderId;
      if (orderItemId) condition.orderItemId = orderItemId;
      if (graphicPosition) condition.graphicPosition = graphicPosition;

      setMsg(null);
      setCondition({ ...condition });
      conditionRef.current = condition;
      isQueueCompletedRef.current = false;
    });
    // eslint-disable-next-line
  }, [settings]);

  // onCreateXMLResult
  const onCreateXMLResult = useCallback(() => {
    //gtx result
    socket.on("on-queue-gtx-result", (result) => {
      console.log("* on-queue-gtx-result init");
      console.log("- result: ", result);
      // console.log('- ssOrderRef: ', ssOrderRef.current )
      // console.log('- itemRef: ', itemRef.current)
      // console.log('- selectedPositionRef: ', selectedPositionRef)

      if (result) {
        if (result?.message) {
          let { message } = result;
          console.log("- message: ", message);
          // return error
          if (message?.code < 0) {
            bannerVariant.current = "danger";
            if (message?.code)
              setMsg(`${message?.code}: ${message.description}`);
          }
          if (_.isNil(message?.code)) {
            bannerVariant.current = "danger";
            let feedback = "Unknown issue";
            if (_.isObject(message)) {
              if (message?.description) feedback = message.description;
            }
            setMsg(feedback);
          }
          // return 0: success
          else {
            bannerVariant.current = "info";
            setMsg(message.description);
          }
          // result?.message is null
        } else {
          const description = result?.description ?? result?.description;
          let feedback = "Unknown issue";
          if (description) {
            bannerVariant.current = "danger";
            feedback = description;
            setMsg(feedback);
          }
        }

        if (result?.printed) {
          if (itemRef.current)
            itemRef.current = { ...itemRef.current, printed: result.printed };
          if (activePackingItemsRef.current) {
            refetchActivePackingItems();
          }
        }
      }
      isQueueCompletedRef.current = true;
      setLoading(false);
    });

    //kothari result
    socket.on("on-queue-kothari-result", (result) => {
      console.log("* on-queue-kothari-result init");
      console.log("- result: ", result);
      // console.log('- ssOrderRef: ', ssOrderRef.current )
      // console.log('- itemRef: ', itemRef.current)
      // console.log('- selectedPositionRef: ', selectedPositionRef)

      if (result) {
        bannerVariant.current = "info";
        result?.message && setMsg(result?.message);
        // console.log('- result.printed: ', result.printed)

        if (result?.printed) {
          if (ssOrderRef.current)
            itemRef.current = { ...itemRef.current, printed: result.printed };
          if (activePackingItemsRef.current) {
            refetchActivePackingItems();
          }
        }
      }
      setLoading(false);
      setDisableCancelQueueBtn(false);
      isQueueCompletedRef.current = true;
    });

    socket.on("on-queue-cadlink-result", async (result) => {
      console.log("* on-queue-cadlink-result init");
      // console.log("- result: ", result);
      // console.log('- ssOrderRef: ', ssOrderRef.current )
      // console.log('- itemRef: ', itemRef.current)
      // console.log('- printQtyRef: ', printQtyRef.current, typeof printQtyRef.current)
      // console.log('- selectedPositionRef: ', selectedPositionRef)
      // console.log('- conditionRef: ', conditionRef.current)

      if (result) {
        bannerVariant.current = "info";
        if (result?.name === "Error ") {
          bannerVariant.current = "warning";
        } else {
          if (result?.printed) {
            // console.log('- result.printed: ', result.printed)
            if (itemRef.current)
              itemRef.current = { ...itemRef.current, printed: result.printed };
            
            if (activePackingItemsRef.current) {
              refetchActivePackingItems();
            }
          }
        } 
        result?.message && setMsg(result?.message);
      }
      setLoading(false);
      setDisableCancelQueueBtn(false);
      isQueueCompletedRef.current = true;
    });

    // eslint-disable-next-line
  }, []);

  // onGTXPreset
  const onGTXPreset = useCallback(() => {
    socket.on("on-gtx-preset-result", (result) => {
      console.log("- on-gtx-preset-result.name: ", result?.name);
      // setGTXPreset(result);
      gtxPresetRef.current = result;
    });
  }, []);

  // onGetGraphicDimensions
  const onGetGraphicDimensions = useCallback(() => {
    socket.on("on-get-graphic-dimensions-result", (result) => {
      console.log("on-get-graphic-dimensions-result", result);
      if (result?.message) {
        setMsg(result.message);
        return;
      } else {
        setGraphicDimension(result);
      }
    });
  }, []);

  useEffect(() => {
    console.log("* settingsRef & sessions & hostname hook ran");
    // console.log('- sessions: ', sessions);
    // console.log('- hostname: ', hostname);
    if (settingsRef.current) {
      // console.log('- settingsRef: ', settingsRef)
      // console.log('- settingsRef.current?.useUnlockedPrintflo: ', settingsRef.current?.useUnlockedPrintflo)
      if(!settingsRef.current?.useUnlockedPrintflo && sessions && hostname) {
        if (Boolean(sessions.length) && !_.isEmpty(hostname)) {
          const matchedSession = _.find(sessions, {
            hostname,
            usedFor: "Printing",
          });
          // console.log('- matchedSession: ', matchedSession)
          if (matchedSession) {
            // console.log('- matchedSession: ', matchedSession);
            // console.log('- matchedSession.dateOfExpiry: ', toDate(new Date(matchedSession.dateOfExpiry)));
            const diffInDays = differenceInDays(
              toDate(new Date(matchedSession.dateOfExpiry)),
              toDate(new Date())
            );
            // console.log('- diffInDays: ', diffInDays);
            if (diffInDays >= 0 && diffInDays <= 30) {
              let tempMsg = "Your license for Printflo is expiring ";
              if (diffInDays >= 1) {
                tempMsg =
                  tempMsg +
                  `${diffInDays + 1} days, expires on ${format(
                    toDate(new Date(matchedSession.dateOfExpiry)),
                    "E LLL d yyyy"
                  )}.`;
              } else if (diffInDays === 0) {
                tempMsg = tempMsg + `in today.`;
              }
              setSessionMsg(tempMsg);
            }
            if (diffInDays < 0) {
              isSessionValid.current = false;
              setSessionMsg(`
                Your license for Printflo is expired after ${format(
                  toDate(new Date(matchedSession.dateOfExpiry)),
                  "E LLL d yyyy"
                )}.`);
            }
          } else {
            isSessionValid.current = false;
            setSessionMsg(`The session is not valid, please contact tech-support@printingify.com`);
          }
        }
      }
      
        console.log('- defaultIntegratedAutomation: ', settingsRef.current?.defaultIntegratedAutomation);
        console.log('- hostname: ', hostname);
        if(hostname && Boolean(settingsRef.current?.cadLinkQueues?.length)) {
          console.log('- settingsRef.current?.cadLinkQueues: ', settingsRef.current?.cadLinkQueues);
          for (let index = 0; index < settingsRef.current.cadLinkQueues.length; index++) {
            const q = settingsRef.current.cadLinkQueues[index];
            for (let i = 0; i < q?.hostnames.length; i++) {
              const h = q?.hostnames[i];
              // console.log(index, i, h)
              if(hostname === h) {
                if(selectedAutomationRef.current !== 'CADLINK') {
                  setSelectedAutomation('CADLINK');
                  selectedAutomationRef.current = 'CADLINK';
                }

                setSelectedCadlinkQueue(q)
                break;
              }
            }
          }
        }
    }


  }, [settings, sessions, hostname]);

  useEffect(() => {
    console.log('* settingsRef & selectedAutomationRef hook init')
    // console.log('- settingsRef; ', settingsRef.current)
    console.log('- selectedAutomationRef; ', selectedAutomationRef.current)
    // console.log('- selectedPosition; ', selectedPosition)
    // console.log('- selectedPositionRef; ', selectedPositionRef.current)
    console.log('- selectedCadlinkQueue; ', selectedCadlinkQueue)
    msg && setMsg(null)
    
    if(selectedAutomationRef.current && settingsRef.current) {
      if(selectedAutomationRef.current && _.includes(selectedAutomationRef.current, 'CAD')) {
        console.log('- settingsRef.current?.cadLinkQueues: ', settingsRef.current?.cadLinkQueues)
        if(!Boolean(settingsRef.current?.cadLinkQueues?.length)) {
          setMsg("CADlink is selected for integrated automation but not found CADlink queue(s)")
        } else {
          if(_.isNil(selectedCadlinkQueue)) {
            setMsg('Required at least a CADlink queue method!')
            loading && setLoading(false)
          }
        }
        
      }
    }


  // eslint-disable-next-line
  }, [settings, selectedAutomation, selectedCadlinkQueue])

  //onQueueSubscribeCallback
  useEffect(() => {
    // console.log("redner on QueueEpsonPrint");
    onQueueSubscribeCallback();

    return () => {
      // console.log("clean-up fn on QueueEpsonPrint");
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
  }, [onQueueSubscribeCallback]);

  //onCreateXMLResult
  useEffect(() => {
    onCreateXMLResult();

    return () => {
      socket.removeAllListeners([
        "on-queue-gtx-result",
        "on-queue-kothari-result",
        "on-queue-cadlink-result"
      ]);
    };
  }, [onCreateXMLResult]);

  //onGTXPreset
  useEffect(() => {
    onGTXPreset();

    return () => {
      socket.removeAllListeners(["on-gtx-preset-result"]);
    };
  }, [onGTXPreset]);

  //onGetGraphicDimensions
  useEffect(() => {
    onGetGraphicDimensions();

    return () => {
      socket.removeAllListeners(["on-get-graphic-dimensions-result"]);
    };
  }, [onGetGraphicDimensions]);

  //location hook
  useEffect(() => {
    console.log("* location hook init");
    // console.log('- location: ', location)
    let parsed = queryString.parse(location?.search);
    console.log("- parsed: ", parsed);

    if (parsed?.sku) {
      let condition = {};
      let sku = parsed?.sku ?? parsed.sku;
      let orderId = parsed?.orderId ?? parsed.orderId;
      let orderItemId = parsed?.orderItemId ?? parsed.orderItemId;
      let consolidation = parsed?.consolidation ?? parsed.consolidation;
      let printedFilms = parsed?.printedFilms ?? parsed.printedFilms;
      let qty = parsed?.qty ?? parsed.qty;
      // console.log('- sku: ', sku);
      // console.log('- orderId: ', orderId);
      // console.log("- consolidation: ", consolidation, Boolean(consolidation));
      // console.log("- printedFilms: ", printedFilms, Boolean(printedFilms));
      // console.log("- qty: ", qty, Boolean(qty));

      if (sku) condition["sku"] = sku;
      if (orderId) condition["orderId"] = orderId;
      if (orderItemId) condition["orderItemId"] = orderItemId;
      if (consolidation) condition["consolidation"] = Boolean(consolidation);
      if (printedFilms) condition["printedFilms"] = Boolean(printedFilms);
      if (qty) condition["qty"] = qty;
      console.log("- condition: ", condition);
      setCondition({ ...condition });
      conditionRef.current = condition;
    }
    // eslint-disable-next-line
  }, [location]);

  //refect by condition
  useEffect(() => {
    console.log("* condition hook init");
    console.log("- condition: ", condition);
    if(_.isNil(condition)) {
      queryClient.removeQueries(['ssOrder'])
      ssOrderRef.current = null
    }
    if (condition?.sku && condition?.orderId) {
      refetchPackingItem();
      // refetchSSOrder();
    }

    if (condition?.sku && !condition?.orderId) {
      if(condition?.qty) {
        setPrintQty(condition.qty);
        printQtyRef.current = condition.qty;
      } 
      refetchItemDoc();
      if(condition?.consolidation) refetchActivePackingItems();
    }


    // eslint-disable-next-line
  }, [condition]);

  //item & itemDoc hook
  useEffect(() => {
    console.log("* item & itemDoc hooks init");
    if(item) console.log("- item retrived");
    // console.log("- item: ", item);
    if(itemDoc) console.log("- itemDoc retrived");
    // console.log("- itemDoc: ", itemDoc);
    if (item || itemDoc) {
      itemRef.current = item || itemDoc;
      // console.log('- itemRef.current: ', itemRef.current)
      if (item && !itemDoc) queryClient.removeQueries("itemDoc");

      //graphics
      graphicsRef.current = itemRef.current?._item?._graphics
        ? itemRef.current._item._graphics
        : itemRef.current?._graphics ?? itemRef.current._graphics;
      console.log("- graphicsRef: ", graphicsRef.current);

      if (!settingsRef.current?.useARXfile) {
        if (_.isArray(graphicsRef.current)) {
          if (!Boolean(graphicsRef.current?.length)) {
            if (!disableQueueBtn) setDisableQueueBtn(true);
            if (!isQueueCompletedRef.current)
              isQueueCompletedRef.current = true;
            setMsg("Graphic not defined.");
            return;
          } else {
            //setSelectedPosition
            handleByGraphics(graphicsRef.current);
          }
        }
      } else {
        if (!Boolean(graphicsRef.current?.length)) {
          if (!disableQueueBtn) setDisableQueueBtn(true);
          if (!isQueueCompletedRef.current) isQueueCompletedRef.current = true;
          setMsg("ARX file not defined.");
          return;
        } else {
          //setSelectedPosition
          handleByGraphics(graphicsRef.current);
        }
      }

      //checked-out?
      if (item) {
        let txt;
        if (itemRef.current?.checkedOutQty > 0) {
          txt = "Already checked-out before";
        } else if (
          !_.includes(itemRef.current?._order?.orderStatus, "awaiting")
        ) {
          txt = "Might be already a shipped item";
        }
        toast.dismiss();
        txt &&
          toast.info(txt, {
            position: "bottom-right",
            autoClose: 2000,
          });

        // call ssOrder if source is not manual order
        console.log('+ item?._order?.source: ', item?._order?.source)
        if(item?._order?.source !== 'manual') {
          refetchSSOrder()
        } else {
          isManualOrderRef.current = true;
        }  
      }

      // customPlaten
      let ripEnv = itemRef.current?._item?.ripEnv
        ? itemRef.current._item.ripEnv
        : itemRef.current?.ripEnv ?? itemRef.current.ripEnv;
      console.log("- ripEnv: ", ripEnv);
      if (ripEnv) {
        handleByRipEnv(ripEnv);
      }

      msg && setMsg(null);
      if (itemDoc) {
        if (activePackingItemsRef.current) {
          if (Boolean(activePackingItemsRef.current.length)) {
            setMsg(
              `Use the Print Queue icon button for saving printed count data in Active Order Items`
            );
          }
        } 
      }
    } // end-if itemDoc

    // eslint-disable-next-line
  }, [item, itemDoc, activePackingItemsRef, settings]);

  useEffect(() => {
    console.log('- ssOrder hook init')
    // console.log(ssOrder)
    if(ssOrder) {
      if(ssOrder?.orderStatus==='cancelled') {
        bannerVariant.current = "danger";
        setMsg('Unable to queue since the order was cancelled')
        setDisableQueueBtn(true)
      } else {
        disableQueueBtn && setDisableQueueBtn(false)
      }
    }
    // eslint-disable-next-line
  }, [ssOrder]);

  useEffect(() => {
    console.log("* selectedPosition hook init");
    // console.log('- selectedPosition: ', selectedPosition);
    // console.log('- selectedPositionRef: ', selectedPositionRef.current);
    // console.log('- settings: ', settings)
    // console.log('- settingsRef: ', settingsRef)
    // console.log('- isManualOrderRef: ', isManualOrderRef)

    if(selectedPosition || selectedPositionRef.current) {
      if (isManualOrderRef.current && disableQueueBtn) setDisableQueueBtn(false);
      // get mockup link
      let graphicFileName = selectedPosition?.graphicFileName 
        ? selectedPosition.graphicFileName
        : selectedPositionRef?.current?.graphicFileName 
        ?? selectedPositionRef.current.graphicFileName;
      
      console.log("- graphicFileName: ", graphicFileName);
      
      if (graphicFileName) {
        let { REACT_APP_SOCKET_ENDPOINT } = process.env;
        if (/^true$/.test(settingsRef.current?.useLocalRepository)) {
          console.log(
            "> in case of useLocalRepository: emit on-get-graphic-dimensions"
          );
          socket.emit("on-get-graphic-dimensions", graphicFileName);
          setPreSignedUrl(
            `${REACT_APP_SOCKET_ENDPOINT}/graphics/${graphicFileName}`
            // `https://localhost:8080/graphics/${graphicFileName}`
          );
        } else {
          const key = `${
            settingsRef.current?.aws?.graphics || "graphics"
          }/${graphicFileName}`;
          getS3presign({ key }).then((response) => {
            // console.log("- getS3presign response:", response);
            setPreSignedUrl(() => response?.url);
          });
        }
      }

      // warning platen change
      let platen =
        selectedPositionRef?.current?.platen ??
        selectedPositionRef.current.platen;

      if (platen) {
        if (
          /^true$/.test(settingsRef.current?.platenChangeWarning) && _.includes(!settingsRef.current?.defaultIntegratedAutomation, "CAD")
        ) {
          warnPlatenChange(platen);
        }
      }

      if (_.isNil(queueCountRef.current)) queueCountRef.current = 0;

      //check ripEnv from selectedPositionRef
      if (selectedPositionRef.current?.ripEnv) {
        console.log(
          "- selectedPositionRef?.current?.ripEnv: ",
          selectedPositionRef.current.ripEnv
        );
        handleByRipEnv(selectedPositionRef.current.ripEnv);
      }

      if(selectedAutomationRef.current && selectedAutomationRef.current.includes('CAD') && _.isNil(selectedPositionRef.current?.media)) {
        const defaultMedia = _.find(settingsRef.current?.media, {isDefault: true});
        // console.log('- defaultMedia; ', defaultMedia)
        if(defaultMedia) {
          if(defaultMedia?.code) {
            setSelectedPosition(current => {
              let retVal = {...current, media: defaultMedia.code}
              selectedPositionRef.current = retVal;
              return retVal
            })
          } 
        } else {
          if(Boolean(settingsRef.current?.media.length)) {
            setSelectedPosition(current => {
              let retVal = {...current, media: settingsRef.current?.media[0]?.code}
              selectedPositionRef.current = retVal;
              return retVal
            })
          }
        
            
        }
      }
    }


    // eslint-disable-next-line
  }, [selectedPosition, settings, isManualOrderRef]);
  // }, [settingsRef, selectedAutomationRef, selectedPosition]);

  useEffect(() => {
    console.log('* autoQueue hook init')
    // autoQueue
    if (settingsRef.current?.autoQueue) {
      console.log("- autoQueue is true");
      console.log("- isSessionValid: ", isSessionValid);
      console.log("- queueCountRef: ", queueCountRef);
      console.log("- conditionRef: ", conditionRef);
      isQueueCompletedRef.current = false;
      if (
        queueCountRef.current === 0 &&
        isSessionValid.current &&
        !conditionRef?.current?.consolidation
      ) {
        setTimeout(() => {
          console.log('*** call queuePrint')
          queuePrint();
        }, 2000);
      }
    }
    // eslint-disable-next-line
  },[settingsRef.current, isSessionValid.curent, queueCountRef.current, conditionRef.current])

  const handleByRipEnv = (ripEnv) => {
    console.log("* handleByRipEnv init");
    // console.log("- ripEnv: ", ripEnv);
    // console.log('- selectedAutomation: ', selectedAutomation);
    // console.log('- settings.useCustomPlatens: ', settingsRef?.current?.useCustomPlatens);

    if (
      _.includes(selectedAutomation, "GTX") &&
      !settingsRef.current?.useARXfile
    ) {
      socket.emit("on-gtx-preset", ripEnv);
    }

    if (settingsRef?.current?.useCustomPlatens) {
      getSetCustomPlaten(ripEnv);
    }
  };

  const handleByGraphics = (graphics) => {
    console.log("* handleByGraphics init");
    // console.log('- graphics: ', graphics)
    // setSelectedPosition
    if (graphics.length === 1) {
      // console.log("- graphics[0]", graphics[0]);
      const graphic = graphics[0]
      if (graphic?.graphicPosition.toLowerCase() !== "back") {
        setSelectedPosition(graphic);
        selectedPositionRef.current = graphic;
      }
    } else {
      console.log('- conditionRef: ', conditionRef)
      if(conditionRef.current?.graphicPosition) {
        for(let g of graphics) {
          // console.log(g)
          if(g?.graphicPosition) {
            if(g.graphicPosition === conditionRef.current?.graphicPosition) {
              setSelectedPosition(g);
              selectedPositionRef.current = g;
              break;
            }
          }
        }
      }
    }
  };

  const warnPlatenChange = (platen) => {
    console.log("* warnPlatenChange init");
    setPlatenValue((currentPlaten) => {
      console.log("- currentPlaten: ", currentPlaten);
      console.log("- platen: ", platen);

      if (currentPlaten !== platen) {
        bannerVariant.current = "danger";
        setMsg(`Replace the platen with ${platen}`);
      }
      return platen;
    });
  };

  const queuePrint = async (orderItem = null) => {
    console.log("* queuePrint init");
    console.log("[queuePrint] isSessionValid: ", isSessionValid);
    // console.log("- orderItem: ", orderItem);
    // console.log("- orderItem?.sku: ", orderItem?.sku);
    if (!isSessionValid.current) {
      alert("Your license for Printflo is expired or this station is not allowed for printing");
      setDisableQueueBtn(true);
      setDisableCancelQueueBtn(true);
      return;
    }
    queueCountRef.current = queueCountRef.current + 1;
    if (orderItem?.sku) itemRef.current = { _item: itemRef.current, ...orderItem};

    setLoading(true);
    // console.log("[queuePrint] itemRef.current: ", itemRef.current);
    // console.log("- selectedPositionRef.current", selectedPositionRef.current);
    // console.log("- gtxPresetRef.current: ", gtxPresetRef.current);
    // console.log("- printQty: ", printQty);
    // console.log("- printQtyRef.current: ", printQtyRef.current);

    toast.dismiss();
    if (msg) setMsg(null);

    if (itemRef.current && selectedPositionRef.current) {
      let dimensions;
      if (graphicDimensions) {
        console.log("- graphicDimensions", graphicDimensions);
        dimensions = graphicDimensions;
      }

      let _id = itemRef.current?._id;
      let sku = itemRef.current?.sku;
      let ripEnv = selectedPositionRef.current?.ripEnv
        ? selectedPositionRef.current.ripEnv
        : itemRef.current?._item?.ripEnv
        ? itemRef.current._item.ripEnv
        : itemRef.current?.ripEnv ?? itemRef.current.ripEnv;
      
      let graphicPosition = selectedPositionRef.current?.graphicPosition;

      let itemObj = { _id, sku, ripEnv };
      let args = {
        item: itemObj,
        selectedPosition: selectedPositionRef.current,
        dimensions,
      };
      let updatePrinted;

      //for updating printed.qty
      // if(ssOrderRef.current && itemRef.current?.printed) {
      if (itemRef.current?.printed) {
        let tempPrinted = _.find(itemRef.current?.printed, (value) => {
          return _.includes(
            value.position.toLowerCase(),
            graphicPosition.toLowerCase()
          );
        }) || {
          qty: 0,
        };
        // console.log("- tempPrinted.qty: ", tempPrinted?.qty);

        if (tempPrinted.qty < itemRef.current.quantity) {
          let _id = itemRef?.current?._id;
          // console.log("- _id, position:(printed) ", _id, graphicPosition);
          updatePrinted = {
            _id,
            graphicPosition,
            hostname,
            username: user?.username,
            printedQty: tempPrinted.qty + 1,
          };
        }
      }

      //GTX
      if (_.includes(selectedAutomation, "GTX")) {
        const useARXfile = settingsRef.current?.useARXfile
          ? settingsRef.current?.useARXfile
          : false;
        const arxFileName =
          selectedPositionRef.current?.arxFileName ??
          selectedPositionRef.current?.arxFileName;
        console.log("-useARXfile: ", useARXfile);
        console.log("-arxFileName: ", arxFileName);
        args["gtxPreset"] = gtxPresetRef.current;

        if (!_.has(itemRef.current, "_item")) {
          console.log(
            '- _.has(itemRef.current, "_item"): ',
            _.has(itemRef.current, "_item")
          );
          args["printQty"] = printQtyRef.current;
        }

        if (useARXfile && !arxFileName) {
          setMsg("ARX file name is missing");
          setLoading(false);
          return;
        }

        if (useARXfile && arxFileName) {
          args["useARXfile"] = useARXfile;
          args["arxFileName"] = arxFileName;
        }

        if (useARXfile && arxFileName) {
          socket.emit("on-queue-gtx", {
            selectedPosition: selectedPositionRef.current,
            updatePrinted,
            useARXfile,
            arxFileName,
          });
          return;
        } else {
          await getGTXQueueInfo(args)
            .then((result) => {
              if (result) {
                console.log("- result(GTX)", result);
                const { print_size, profile } = result;
                bannerVariant.current = "info";
                setMsg("Sent a queue data to Command Line...");

                socket.emit("on-queue-gtx", {
                  print_size,
                  profile,
                  selectedPosition: selectedPositionRef.current,
                  updatePrinted,
                  useARXfile,
                  arxFileName,
                });
              }
            })
            .catch((error) => {
              setMsg(error);
              setLoading(false);
              return;
            });
        }
      }
      //KOTHARI
      if (_.includes(selectedAutomation, "KOTHARI")) {
        await createXml(args)
          .then((result) => {
            if (result) {
              // console.log("- result(KOTHARI)", result);
              socket.emit(
                "on-queue-kothari",
                result,
                sku,
                selectedPositionRef.current,
                printQtyRef.current.toString(),
                updatePrinted
              );
            }
          })
          .catch((error) => {
            console.log("queuePrint error: ", error);
            setMsg(error);
            setLoading(false);
            return;
          });
      }
      //CADLINK
      if (_.includes(selectedAutomation, "CADLINK")) {
        // console.log("- selectedAutomation: ", selectedAutomation);
        console.log("[queuePrint] selectedCadlinkQueue: ", selectedCadlinkQueue)
        console.log("[queuePrint] selectedPositionRef: ", selectedPositionRef)
        console.log("[queuePrint] usePrintMode: ", settingsRef.current?.usePrintMode)
        if(_.isNil(selectedCadlinkQueue)) {
          setMsg('CADlink queue is required');
          loading && setLoading(false);
          return
        }
        if(settingsRef.current?.usePrintMode && selectedPositionRef.current?.printMode) {
          ripEnv = selectedPositionRef.current?.printMode
        }
        console.log("[queuePrint] ripEnv: ", ripEnv)
        socket.emit("on-queue-CADlink", {
          orderId: itemRef?.current?.orderId,
          orderItemId: itemRef?.current?.orderItemId,
          ripEnv,
          selectedPosition: selectedPositionRef.current,
          selectedCadlinkQueue,
          updatePrinted
        }, (response) => {
          console.log('[queuePrint] response: ', response);
          bannerVariant.current = "info";
          setLoading(false);
          setDisableCancelQueueBtn(false);
          if (response?.error) {
            bannerVariant.current = "danger";
          } else {
            isQueueCompletedRef.current = true;
            if (response?.printed) {
              // console.log('- result.printed: ', result.printed)
              if (itemRef.current)
                itemRef.current = { ...itemRef.current, printed: response.printed };
              
              if (activePackingItemsRef.current) {
                refetchActivePackingItems();
              }
            }
          }
          setMsg(response.message)
        });
      }

    } else {
      bannerVariant.current = "danger";
      setMsg("Item or selected position not found");
      setLoading(false);
      return;
    }
  };

  const PlatenName = ({ platen }) => {
    const foundPlaten = _.find(platens, { code: platen });
    // console.log("foundPlaten", foundPlaten);
    return (
      <span className="content content-ref">
        {foundPlaten && `${foundPlaten.name}(${platen})`}
      </span>
    );
  };

  const MediaName = ({media, index}) => {
    // console.log('* MediaName init')
    // console.log('- media: ', media)
    // console.log('- index: ', index)
    // console.log('- selectedPositionRef: ', selectedPositionRef)
    return (
      <select
        name="media"
        value={media ? media : selectedPositionRef.current?.media ? selectedPositionRef.current.media : undefined}
        onChange={(e) => {
          graphicsRef.current[index] = {...graphicsRef.current[index], media: e.target.value}
          setSelectedPosition((current) => {
            selectedPositionRef.current = {...current, media: e.target.value}
            return {...current, media: e.target.value}
          })
          msg && setMsg(null)
        }}
      >
        <option value="">-- Choose media --</option>
        {settingsRef.current?.media &&
          settingsRef.current?.media.map((p) => {
            return (
              <option value={p.code} key={p.code}>
                {p.name} ({p.size.width}x{p.size.height} inch)
              </option>
            );
          })}
      </select>
    )
  }

  const Printed = ({ position }) => {
    // console.log("* Printed init");
    // console.log("[Printed] position: ", position);
    // console.log("[Printed] itemRef.current: ", itemRef.current);
    const foundPrinted = _.find(itemRef.current?.printed, (value) => {
      // console.log("[Printed] value: ", value);
      
      return _.includes(value?.position?.toLowerCase(), position.toLowerCase());
    });
    // console.log("- foundPrinted", foundPrinted);
    return <b className="printed-qty">{foundPrinted ? foundPrinted.qty : 0}</b>;
  };

  const PrintedStatus = ({ position }) => {
    // console.log("* PrintedStatus init");
    // console.log("[PrintedStatus] position: ", position);
    const foundPrinted = _.find(itemRef.current?.printed, (value) => {
      return _.includes(value?.position?.toLowerCase(), position.toLowerCase());
    });
    // console.log("foundPrinted", foundPrinted);
    return foundPrinted && foundPrinted.qty >= itemRef.current?.quantity ? (
      <FaCheckCircle />
    ) : (
      <FaWindowClose />
    );
  };

  const getSetCustomPlaten = (ripEnv) => {
    const regex = /\d+[x]\d+/gim;
    let isCustomPlaten = regex.test(ripEnv);
    console.log(`- isCustomPlaten: ${isCustomPlaten}`);
    if (isCustomPlaten) {
      let matched = ripEnv.match(regex);
      console.log("- matched", matched);
      if (Boolean(matched.length)) {
        toast.dismiss();
        let customPlaten = matched[0];
        // console.log("- customPlaten: ", customPlaten);
        let platenName = `Custom Platen (${customPlaten})`;
        setCustomPlaten(platenName);
        toast.info(`${platenName} was applied`, {
          position: "bottom-right",
          autoClose: false,
          onOpen: () => {
            warnPlatenChange(platenName);
          },
        });
      }
    }
  };

  const handleSelectedCadlinkQueue = (e) => {
    console.log('* handleSelectedCadlinkQueue init')
    // console.log('e.target.value: ', e.target.value)
    const found = _.find(settingsRef.current?.cadLinkQueues, {name: e.target.value})
    if(found) setSelectedCadlinkQueue(found);
  }

  const GraphicsTable = ({ graphics, useARXfile, selectedAutomation, usePrintMode }) => {
    let index = 0;
    return (
      <div className="queue-print-graphics card mb-10">
        <Table
          data={graphics}
          columns={[
            <TableHeader label="Check position to print" />,
            <TableHeader label="Graphic File" />,
            <TableHeader
              label="ARX File"
              className={!useARXfile && "hidden"}
            />,
            <TableHeader label="Size(width/height)" />,
            <TableHeader label="Platen" className={_.includes(selectedAutomation, "CAD") && "hidden"}/>,
            <TableHeader label="Media(CADlink)" className={!_.includes(selectedAutomation, "CAD") && "hidden"}/>,
            <TableHeader label="Rip Env." />,
            <TableHeader label="Print Mode(CADlink)" 
              className={!_.includes(selectedAutomation, "CAD") ? "hidden" : !usePrintMode ? "hidden" : ""} />,
            <TableHeader label="Printed" className={itemDoc && "hidden"} />,
            <TableHeader label="status" className={itemDoc && "hidden"} />,
          ]}
        >
          {({ datum }) => {
            
            return (
              <Row key={datum.graphicPosition}>
                <Cell>
                  <Checkbox
                    label={datum.graphicPosition}
                    checked={
                      selectedPosition &&
                      selectedPosition.graphicPosition === datum.graphicPosition
                        ? true
                        : false
                    }
                    onChange={() => {
                      if (disableQueueBtn) setDisableQueueBtn(false);
                      setSelectedPosition(datum);
                      selectedPositionRef.current = datum;
                    }}
                  />
                </Cell>
                <Cell>{datum?.graphicFileName ? datum.graphicFileName : ""}</Cell>
                <Cell className={!useARXfile && "hidden"}>
                  {datum.arxFileName ? datum.arxFileName : ""}
                </Cell>
                <Cell>
                  <input
                    type="text"
                    id="width"
                    defaultValue={
                      _.has(datum, "size") &&
                      !_.isNil(datum.size.width)
                        ? datum.size.width
                        : ""
                    }
                    style={{ maxWidth: 60 }}
                    placeholder="width"
                    disabled={true}
                  />
                  <input
                    type="text"
                    id="height"
                    defaultValue={
                      _.has(datum, "size") &&
                      !_.isNil(datum.size.height)
                        ? datum.size.height
                        : ""
                    }
                    style={{ maxWidth: 60, marginLeft: 10 }}
                    placeholder="height"
                    disabled={true}
                  />
                </Cell>
                { !_.includes(selectedAutomation, 'CAD') 
                 ? (
                  <Cell>
                    {customPlaten ? (
                      <span>{customPlaten}</span>
                    ) : (
                      <PlatenName platen={datum.platen} />
                    )}
                  </Cell>

                 ) : (
                  <Cell>
                    <MediaName media={datum?.media} index={index} />
                  </Cell>
                 )
                }
                <Cell>{datum?.ripEnv ? datum.ripEnv : ""}</Cell>
                {
                  (selectedAutomation.toLowerCase().includes('cad') && usePrintMode) && (
                    <Cell>{datum?.printMode ? datum.printMode: ""}</Cell>
                  )
                }
                <Cell className={itemDoc && "hidden"}>
                  <Printed position={datum.graphicPosition} />
                </Cell>
                <Cell className={itemDoc && "hidden"}>
                  <PrintedStatus position={datum.graphicPosition} />
                </Cell>
                {index = index + 1}
              </Row>
            )
          }}
        </Table>
      </div>
    );
  };

  const ActivePackingItemsTable = ({ activePackingItems }) => {
    let index = 0;

    return (
      <div className="active-packingitems card mb-10 p-10">
        <h5>Active Order Items:: {activePackingItems.length}</h5>
        <div className="list">
          <Table
            data={activePackingItems}
            columns={[
              <TableHeader label="No" />,
              <TableHeader label="Order Number" />,
              <TableHeader label="Ordered" />,
              <TableHeader label="Printed" />,
              <TableHeader label="Checked Out" />,
              <TableHeader label="Synced At" />,
              <TableHeader label="Action" />,
            ]}
          >
            {({ datum }) => {
              index = index + 1;
              return (
                <Row key={datum._id}>
                  <Cell>{index}</Cell>
                  <Cell>
                    <Link to={`workorder-details/${datum?.orderId}`}>
                      <span className="block">
                        {datum.orderNumber}
                        {datum.isBundle && (
                          <span>
                            <FaBold
                              size="10px"
                              color="#007CAD"
                              className="ml-10"
                            />
                          </span>
                        )}
                      </span>
                    </Link>

                    <span className="block">
                      {datum.sequence} / {datum.count}
                    </span>
                  </Cell>
                  <Cell>{datum?.quantity ? datum.quantity : 0}</Cell>
                  <Cell>
                    <span className="active-packingitem-printed">
                      {datum?.printed ? _.sumBy(datum.printed, "qty") : 0}
                    </span>
                  </Cell>
                  <Cell>{datum?.checkedOutQty ? datum.checkedOutQty : 0}</Cell>
                  <Cell>
                    {datum?.createdAt
                      ? moment(datum.createdAt).format("MM-DD-YYYY")
                      : ""}
                  </Cell>
                  <Cell>
                    <div className="action-btn-list d-flex align-items-center">
                        
                        <Button
                          className="ml-10"
                          variant="primary"
                          onClick={() => queuePrint(datum)}
                          leftGlyph={<FaPrint />}
                          size="small"
                        >
                          Queue Print
                        </Button>
                        <Tooltip
                          className="lg-tooltip-container"
                          darkMode={true}
                          trigger={
                            <IconButton
                              onClick={() => printOrderItemLabel({item: datum, settings})}
                              className="swing-icon"
                              aria-label="Print a label"
                            >
                              <FaQrcode />
                            </IconButton>
                          }
                        >
                          Print a label
                        </Tooltip>
                      </div>  

                  </Cell>
                </Row>
              );
            }}
          </Table>

        </div>
      </div>
    );
  };

  const onCancelQueueResult = useCallback(() => {
    socket.on("on-cancel-queue-result", (result) => {
      console.log("* on-cancel-queue-result init");
      console.log("- result: ", result);
      // setDisableQueueBtn(false);
      setMsg(result);
    });
  }, []);

  //onCancelQueueResult
  useEffect(() => {
    onCancelQueueResult();

    return () => {
      socket.removeAllListeners(["on-cancel-queue-result"]);
    };
  }, [onCancelQueueResult]);

  const cancelQueueHandler = async () => {
    console.log("* cancelQueueHandler init");
    console.log("- item", item);
    const confirm = window.confirm("Confirm to delete!");
    if (confirm) {
      socket.emit("on-cancel-queue", item?.sku);
    }
  };

  const handlePrintSkuLabel = () => {
    console.log('* handlePrintLabel init')
    // console.log('- printQtyRef: ', printQtyRef.current)
    // console.log('- itemRef: ', itemRef.current)
    let zpl = skuLabelTemplateQR({ item: itemRef.current, printQty: printQtyRef.current });
    // console.log('- zpl: ', zpl)
    zpl && socket.emit("on-sku-print", zpl);
    setModalConfirmIsOpen(false);
  }
  

  return (
    <>
      <Title title={title} />
      <PageMainHeader
        title={title}
        user={user}
        settings={settings}
        description={
          _.includes(selectedAutomation, "GTX")
            ? "Integrated Brother GTX Command line"
            : _.includes(selectedAutomation, "KOTHARI")
            ? "Integrated Kothari automation"
            : _.includes(selectedAutomation, "CADLINK")
            && "Integrated CADlink automation"
        }
      />
      <ToastContainer
        theme="colored"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <section className="primary queue-print">
        {sessionMsg && (
          <Banner className="mb-10" variant="warning">
            {sessionMsg}
          </Banner>
        )}
        <div className="queue-default-values-wrapper card">
          <div className="value-wrapper">
            <label htmlFor="integratedAutomation">Integrated Automation:</label>
            <select
              value={selectedAutomation ? selectedAutomation : ""}
              onChange={(e) => {
                if (_.includes(e.target.value, "GTX")) {
                  if (_.isNil(gtxPresetRef.current)) {
                    let ripEnv = itemRef.current?._item?.ripEnv;
                    if (ripEnv) {
                      socket.emit("on-gtx-preset", ripEnv);
                    } else {
                      socket.emit("on-gtx-preset", "All Colors");
                    }
                  }
                }
                setSelectedAutomation(e.target.value);
                selectedAutomationRef.current = e.target.value;
              }}
              id="integratedAutomation"
            >
              {integratedAutomations.map((a, index) => {
                return (
                  <option value={a?.value} key={index}>
                    {a.name}
                  </option>
                );
              })}
            </select>
          </div>
          
          { (settingsRef.current?.cadLinkQueues && (selectedAutomation && _.includes(selectedAutomation, 'CAD'))) 
            && (
              <div className="value-wrapper">
                <label htmlFor="selectedCadlinkQueue">CADlink Queue:</label>
                <select
                  value={selectedCadlinkQueue?.name ? selectedCadlinkQueue.name : ""}
                  onChange={(e) => handleSelectedCadlinkQueue(e)}
                  id="selectedCadlinkQueue"
                >
                  <option value="">== Select a queue ==</option>
                  {settingsRef.current?.cadLinkQueues.map((c, index) => {
                    return (
                      <option value={c.name} key={index}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
              </div>

            ) 
          }

        </div>
        <div className="queue-print-info card mb-10">
          <div className="title-content-list">
            <div className="title-content">
              <span className="title">SKU</span>
              <span className="d-block content">
                {item ? item?.sku : itemDoc && itemDoc?.sku}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Description</span>
              <span>{item ? item?.name : itemDoc && itemDoc?.description}</span>
            </div>
            {item && (
              <>
                <div className="title-content">
                  <span className="title">Order Quantity</span>
                  <span>{item && item?.quantity}</span>
                </div>
                <div className="title-content">
                  <span className="title">Order No.</span>
                  <Link to={`workorder-details/${item && item?.orderId}`}>
                    <span>{item && item?.orderNumber}</span>
                  </Link>
                </div>

                <div className="title-content">
                  <span className="title">Order Date</span>
                  <span>
                    {item &&
                      moment(item?._order?.orderDate)
                        .local()
                        .format("MM-DD-YYYY")}
                  </span>
                </div>
              </>
            )}
            <div className="title-content">
              <span className="title">Component SKU</span>
              <span>
                {item ? item?._item?.component : itemDoc && itemDoc.component}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Rip Environment</span>
              <span>
                {item ? item?._item?.ripEnv : itemDoc && itemDoc.ripEnv}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Position</span>
              <span>
                {item
                  ? item?._item?.graphicPosition
                  : itemDoc && itemDoc.graphicPosition}
              </span>
            </div>
            {item && Boolean(item?.options?.length) && (
              <div className="title-content">
                <span className="title">Order Item Options</span>
                <ItemOptions options={item.options} />
              </div>
            )}
            {(itemDoc && !conditionRef.current?.consolidation) && (
              <div className="d-flex">
                <div className="title-content" >
                  <span className="title">Print Quantity</span>
                  <div className="d-flex">
                    <input
                      type="text"
                      // value={printQtyRef.current}
                      value={printQty}
                      onChange={(e) => {
                        setPrintQty(e.target.value)
                        printQtyRef.current = e.target.value;
                      }}
                      style={{ maxWidth: "70px", marginRight: 10 }}
                      className="text-center"
                    />
                    <Tooltip
                      className="lg-tooltip-container" 
                      darkMode={true}
                      trigger={
                        <IconButton
                          onClick={() => handlePrintSkuLabel()}
                          className="swing-icon"
                          aria-label="Print labels"
                        >
                          <FaQrcode />
                        </IconButton>}
                    >
                      Print labels
                    </Tooltip>

                  </div>
                </div>
                
              </div>
            )}
            
            <div className="title-content">
              <span className="title">Order Status</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  { ssOrderRef.current?.orderStatus 
                    ? ssOrderRef.current.orderStatus 
                    : item && item?._order?.orderStatus
                    ? item._order.orderStatus
                    : null
                  }
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>

            <div className="title-content">
              <span className="title">Customer Notes</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  {ssOrderRef.current?.customerNotes}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>

            <div className="title-content">
              <span className="title">Internal Notes</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  {ssOrderRef.current?.internalNotes}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>
            {item && (
              <div className="title-content">
                <span className="title">Print label</span>
                <Tooltip
                  className="lg-tooltip-container" 
                  darkMode={true}
                  trigger={
                    <IconButton
                      onClick={() => printOrderItemLabel({item, settings})}
                      className="swing-icon"
                      aria-label="Print labels"
                    >
                      <FaQrcode />
                    </IconButton>}
                >
                  Print order item label
                </Tooltip>
              </div>
            )}
              
          </div>

          <div className="queue-print-img markup">
            <img
              src={
                !_.isNil(selectedPosition)
                  ? !_.isEmpty(selectedPosition.imageUrl)
                    ? selectedPosition.imageUrl
                    : item
                    ? item?._item?.imageUrl
                    : itemDoc && itemDoc?.imageUrl
                  : item
                  ? item?._item?.imageUrl
                  : itemDoc && itemDoc?.imageUrl
              }
              className="responsive-img graphic-image-url"
              alt={item ? item?.sku : itemDoc && itemDoc?.sku}
              onError={(e) =>
                (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
              }
            />
          </div>
          <div className="queue-print-img graphic">
            {selectedPosition && preSignedUrl && (
              <>
                <img
                  src={`${preSignedUrl}`}
                  className="responsive-img graphic-file"
                  alt={selectedPosition.graphicFileName}
                  key={preSignedUrl}
                  onError={(e) =>
                    (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
                  }
                  onLoad={() => {
                    setLoadedArtwork(true);
                  }}
                  style={{ display: !loadedArtwork ? "none" : "block" }}
                />
                {!loadedArtwork && <GridLoader color={"#09804C"} />}
              </>
            )}
          </div>
        </div>

        {graphicsRef.current && (
          <GraphicsTable
            graphics={graphicsRef.current}
            useARXfile={selectedAutomation?.toLowerCase().includes('gtx') && settingsRef.current?.useARXfile}
            selectedAutomation={selectedAutomation}
            usePrintMode = {settingsRef.current?.usePrintMode}
          />
        )}

        {activePackingItemsRef.current && (
          <ActivePackingItemsTable
            activePackingItems={activePackingItemsRef.current}
          />
        )}

        {msg && (
          <Banner variant={bannerVariant.current} className="mb-10">
            {msg}
          </Banner>
        )}

        <div
          className={`d-flex ${
            selectedAutomation === "KOTHARI"
              ? "justify-content-space"
              : "justify-content-end"
          }`}
        >
          {selectedAutomation === "KOTHARI" && (
            <Button
              onClick={cancelQueueHandler}
              leftGlyph={<FaTrashAlt />}
              variant="danger"
              size="large"
              disabled={disableCancelQueueBtn}
            >
              Cancel Queue
            </Button>
          )}

          {!activePackingItemsRef.current && (
            <Button
              className="ml-10"
              variant="primary"
              onClick={queuePrint}
              disabled={disableQueueBtn}
              leftGlyph={<FaPrint />}
              size="large"
            >
              Queue Print
            </Button>
          )}
        </div>
        <ModalPreloader modalPreloaderIsOpen={isLoading || loading} />
        <ConfirmationModal
          open={modalConfirmIsOpen}
          onConfirm={handlePrintSkuLabel}
          onCancel={() => setModalConfirmIsOpen(false)}
          buttonText='Confirm'
          title="Confirm printing labels"
        >
          Click confirm to print labels or cancel to close
        </ConfirmationModal>
        {/* <pre>{itemRef.current && JSON.stringify(itemRef.current, null, 2)}</pre> */}
        {/* <pre>{activePackingItems && JSON.stringify(activePackingItems, null, 2)}</pre>   */}
        {/* <pre>selectedCadlinkQueue {selectedCadlinkQueue && JSON.stringify(selectedCadlinkQueue, null, 2)}</pre>   */}
        {/* <pre>selectedPositionRef.current {selectedPositionRef.current && JSON.stringify(selectedPositionRef.current, null, 2)}</pre>   */}
        {/* <pre>selectedPosition {selectedPosition && JSON.stringify(selectedPosition, null, 2)}</pre>   */}
        {/* <pre>selectedAutomationRef.current {selectedAutomationRef.current}</pre>   */}
        {/* <pre>selectedAutomation {selectedAutomation}</pre>   */}
        {/* <pre>graphicsRef.current {graphicsRef.current && JSON.stringify(graphicsRef.current, null, 2)}</pre>   */}
      </section>
    </>
  );
}