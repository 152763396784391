import React, { useMemo, useState } from "react";
import _ from "lodash";
import "react-image-lightbox/style.css";
import ReactTableContainer from "utils/ReactTableContainer";
import {
  FaBold,
  FaCheckCircle,
  FaEdit,
  FaQrcode,
  FaTshirt,
  FaWindowClose,
} from "react-icons/fa";
import IconButton from "@leafygreen-ui/icon-button";
import Tooltip from "@leafygreen-ui/tooltip";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import Banner from "@leafygreen-ui/banner";

const WorkOrderDetailsItems = ({
  items,
  handlePrintLabel,
  orderItemHandler,
  showEditItemModal,
  platens,
  settings,
  shipstationOrder,
  user,
}) => {
  // console.log("* WorkOrderDetailsItems init");
  // console.log("- items", items);
  // console.log("- platens", platens);
  // console.log("- settings", settings);
  // console.log("- shipstationOrder", shipstationOrder);
  // console.log("- user", user);
  const { usePretreatmentBarcode } = settings;
  const [hiddenCols] = useState(() => {
    let cols = ["_id"];
    if (!usePretreatmentBarcode) cols.push("_item.pretreatmentBarcode");
    return cols;
  });
  // console.log("- usePretreatmentBarcode", usePretreatmentBarcode);

  // const PlatenName = ({ platen }) => {
  //   const foundPlaten = _.find(platens, { code: platen });
  //   // console.log("foundPlaten", foundPlaten);
  //   return (
  //     <span className="block font-size-sm">
  //       {foundPlaten && foundPlaten.name}
  //     </span>
  //   );
  // };

  const columns = useMemo(
    () => [
      { Header: "id", accessor: "_id" },
      {
        Header: "No",
        accessor: "no",
        Cell: ({ row }) => {
          return parseInt(row?.id) + 1;
        },
      },
      {
        Header: "SKU / Instock",
        accessor: "sku",
        Cell: ({ row }) => {
          // console.log('- row: ', row)
          return (
            <>
              <span
                onClick={() =>
                  showEditItemModal({ item: row.original, index: row.index })
                }
                className="link block"
              >
                {row.values.sku}
                {row?.original?.isBundle && (
                  <Tooltip
                    trigger={
                      <span>
                        <FaBold size="10px" color="#007CAD" className="ml-10" />
                      </span>
                    }
                    className="lg-tooltip-container"
                  >
                    Bundle items
                  </Tooltip>
                )}
              </span>
              {row?.original?._item?._inventoryArea && (
                <span className="block font-size-sm">
                  {_.sumBy(row.original._item._inventoryArea, "stock")}
                </span>
              )}
            </>
          );
        },
      },
      { Header: "Ordered", accessor: "quantity" },
      { Header: "Picked", accessor: "pickedQty" },
      {
        Header: "Printed",
        accessor: "printed",
        Cell: ({ row }) => {
          // console.log("- row: ", row);
          let sumPrinted = 0;
          if (row?.original?.printed) {
            sumPrinted = _.sumBy(row?.original?.printed, "qty");
          }
          return sumPrinted;
        },
      },
      { Header: "Checked-out", accessor: "checkedOutQty" },
      {
        Header: "Component / Instock",
        accessor: "_item._component.sku",
        Cell: ({ row }) => {
          let sku = row?.original?._item?._component?.sku;
          let instock = row?.original?._item?._component?.stock;
          return (
            <>
              <span className="block">{sku}</span>
              <span className="block font-size-sm">{instock}</span>
            </>
          );
        },
      },
      // {
      //   Header: "Graphic / Platen",
      //   accessor: "_item._graphics",
      //   Cell: ({ row }) => {
      //     let graphics = [];
      //     graphics = row?.original?._item?._graphics;
      //     // console.log("- graphics: ", graphics);
      //     if (_.isNil(graphics)) return "";

      //     if (graphics.length > 1) {
      //       return <span>Multiple Positions</span>;
      //     } else if (graphics.length === 1) {
      //       return (
      //         <>
      //           <span className="block">{graphics[0].graphicFileName}</span>
      //           {graphics[0].platen && (
      //             <PlatenName platen={graphics[0].platen} />
      //           )}
      //         </>
      //       );
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // { Header: "Rip Env", accessor: "_item.ripEnv" },
      {
        Header: "Pretreatment Barcode",
        accessor: "_item.pretreatmentBarcode",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <div className="action-btn-list">
              <Tooltip
                className="lg-tooltip-container"
                darkMode={true}
                trigger={
                  <IconButton
                    onClick={() =>
                      handlePrintLabel({ item: row.original, settings })
                    }
                    className="swing-icon"
                    aria-label="Print a label"
                  >
                    <FaQrcode />
                  </IconButton>
                }
              >
                Print a label
              </Tooltip>

              {user?.role === "admin" && (
                <Tooltip
                  className="lg-tooltip-container"
                  darkMode={true}
                  trigger={
                    <IconButton
                      onClick={() =>
                        orderItemHandler({ item: row.original, type: "edit" })
                      }
                      className="swing-icon"
                      aria-label="Edit this item"
                    >
                      <FaEdit />
                    </IconButton>
                  }
                >
                  Edit this item
                </Tooltip>
              )}
              <Tooltip
                className="lg-tooltip-container"
                darkMode={true}
                trigger={
                  <IconButton
                    onClick={() => {
                      let url = `/queue-print?sku=${row?.original?.sku}&orderId=${row?.original?.orderId}`;
                      if (row?.original?.orderItemId) {
                        url =
                          url + `&orderItemId=${row?.original?.orderItemId}`;
                      }
                      // let url = `/queue-print-${!_.includes(defaultIntegratedAutomation, "GTX") ? "epson" : "brother"}?sku=${row?.original?.sku}&orderId=${row?.original?.orderId}`;
                      window.location.assign(url);
                    }}
                    className="swing-icon"
                    aria-label="Queue print"
                  >
                    <FaTshirt />
                  </IconButton>
                }
              >
                Queue print
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: "Active",
        accessor: "isActive",
        Cell: ({ row }) => {
          return (
            <span className="d-flex justify-content-center">
              {row?.values?.isActive ? (
                <FaCheckCircle size="1.5em" />
              ) : (
                <FaWindowClose size="1.5em" />
              )}
            </span>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {items && !Boolean(items.length) ? (
        Boolean(shipstationOrder?.items.length) && (
          <div className="page-content card mt-10 p-10">
            <Banner variant="danger" className="mb-10">
              Listed order item(s) from ShipStation.
            </Banner>
            <Table
              data={shipstationOrder.items}
              columns={[
                <TableHeader label="SKU" />,
                <TableHeader label="Order Item Id" />,
                <TableHeader label="Description" />,
                <TableHeader label="Ordered" />,
              ]}
            >
              {({ datum }) => (
                <Row key={datum.lineItemKey}>
                  <Cell>{datum.sku ? datum.sku : ""}</Cell>
                  <Cell>{datum?.orderItemId ? datum.orderItemId : ""}</Cell>
                  <Cell>{datum.name}</Cell>
                  <Cell>{datum.quantity}</Cell>
                </Row>
              )}
            </Table>
          </div>
        )
      ) : (
        <div className="page-content card mt-10 p-10">
          <ReactTableContainer
            columns={columns}
            data={items}
            hiddenColumns={[...hiddenCols]}
            sortBy={[{ id: "sku" }]}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(WorkOrderDetailsItems);
